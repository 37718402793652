import React from 'react';
import { useAmp } from 'next/amp';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import sidekick from '@last-rev/contentful-sidekick-util';

import ErrorBoundary from '../ErrorBoundary';
import Image from '../Image';
import ArtDirectedImage from '../ArtDirectedImage';

import type { MediaOwnerState, MediaProps, MediaVideoProps } from './Media.types';
import { useJsonLd } from '../JsonLd';

// import dynamic from 'next/dynamic';

// const Image = dynamic(() => import('../Image'));
// const ArtDirectedImage = dynamic(() => import('../ArtDirectedImage'));
const Wrapper = ({ children, aspectRatio }: any) =>
  aspectRatio ? (
    <AspectRatioRoot ownerState={{ __typename: 'Media', aspectRatio }}>
      {' '}
      {children}{' '}
    </AspectRatioRoot>
  ) : (
    children
  );

const Media = (props: MediaProps | MediaVideoProps) => {
  const isAmp = useAmp();
  const { addJsonLdItem } = useJsonLd();

  const {
    variant,
    file,
    title,
    fileMobile,
    fileTablet,
    testId,
    sidekickLookup,
    aspectRatio,
    jsonLd,
    ...other
  } = props;
  const ownerState = { ...props };
  // TODO: Add support for video
  const image = file;
  const alt = title || '';

  React.useEffect(() => {
    if (jsonLd) addJsonLdItem(jsonLd);
  }, [jsonLd]);

  if (variant === 'embed' && isAmp) {
    return (
      <ErrorBoundary>
        <Wrapper aspectRatio={aspectRatio}>
          {/* @ts-expect-error */}
          <amp-iframe
            {...sidekick(sidekickLookup)}
            src={image?.url}
            data-testid={testId || 'Media'}
            width={image?.width ?? 800}
            height={image?.height ?? 400}
            layout="responsive"
            sandbox="allow-scripts allow-same-origin"
          />
        </Wrapper>
      </ErrorBoundary>
    );
  }
  if (variant === 'embed') {
    return (
      <ErrorBoundary>
        <Wrapper aspectRatio={aspectRatio}>
          <EmbedWrap ownerState={ownerState}>
            <EmbedRoot
              ownerState={ownerState}
              {...sidekick(sidekickLookup)}
              {...(props as React.IframeHTMLAttributes<any>)}
              src={image?.url}
              sx={{ width: '100%', height: '100%', ...props.sx }}
              data-testid={testId || 'Media'}
            />
          </EmbedWrap>
        </Wrapper>
      </ErrorBoundary>
    );
  }

  if (variant === 'video') {
    return (
      <ErrorBoundary>
        <Wrapper aspectRatio={aspectRatio}>
          <VideoRoot
            ownerState={ownerState}
            {...sidekick(sidekickLookup)}
            preload="auto"
            data-testid={testId || 'Media'}
            {...(props as MediaVideoProps)}
            sx={{ width: '100%', height: '100%', ...props.sx }}>
            <source src={file?.url} />
            Your browser does not support the video tag.
          </VideoRoot>
        </Wrapper>
      </ErrorBoundary>
    );
  }

  if (fileTablet || fileMobile) {
    return (
      <ErrorBoundary>
        <Wrapper aspectRatio={aspectRatio}>
          <ArtDirectedRoot
            ownerState={ownerState}
            {...sidekick(sidekickLookup)}
            {...other}
            title={title}
            file={file}
            fileTablet={fileTablet}
            fileMobile={fileMobile}
            testId={testId || 'Media'}
          />
        </Wrapper>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Wrapper aspectRatio={aspectRatio}>
        <Root
          ownerState={ownerState}
          {...sidekick(sidekickLookup)}
          {...image}
          {...other}
          src={image?.url}
          alt={alt}
          testId={testId || 'Media'}
        />
      </Wrapper>
    </ErrorBoundary>
  );
};

// Define the pieces of the Media customizable through Theme

const shouldForwardProp = (prop: string) =>
  prop !== 'variant' &&
  prop !== 'fileName' &&
  prop !== 'testId' &&
  prop !== 'priority' &&
  prop !== 'sidekickLookup' &&
  prop !== 'sx' &&
  prop !== 'file' &&
  prop !== 'nextImageOptimization';

const Root = styled(Image, {
  name: 'Media',
  slot: 'Root',
  shouldForwardProp: (prop: string) =>
    prop !== 'variant' && prop !== 'fileName' && prop !== 'sidekickLookup',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string; ownerState: MediaOwnerState }>``;

const AspectRatioRoot = styled(Box, {
  name: 'Media',
  slot: 'AspectRatioRoot',
  overridesResolver: (_, styles) => [styles.aspectRatioRoot]
})<{ variant?: string; ownerState: MediaOwnerState }>``;

const ArtDirectedRoot = styled(ArtDirectedImage, {
  name: 'Media',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string; ownerState: MediaOwnerState }>``;

const EmbedRoot = styled('iframe', {
  name: 'Media',
  slot: 'EmbedRoot',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: MediaOwnerState }>``;

const EmbedWrap = styled(Box, {
  name: 'Media',
  slot: 'EmbedWrap',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: MediaOwnerState }>``;

const VideoRoot = styled('video', {
  name: 'Media',
  slot: 'VideoRoot',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string; ownerState: MediaOwnerState }>``;

export default Media;
